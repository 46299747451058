import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FormModal from '../Modal';
import axios from 'axios';

const url = `${process.env.REACT_APP_SERVER_BASE_URL}/send-mail`

console.log(process.env.REACT_APP_SERVER_BASE_URL);

function Section_4(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [fName, setFname] = useState("")
    const [LName, setLname] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [message, setMessage] = useState("")

    async function handleSubmit(e) {
        e.preventDefault()
        if (fName === "" || LName === "" || email === "" || phone === "" || message === "") {
            alert("Please Fill All Data")
        }
        else {
            try {
                let URL = `${url}`
                const response = await axios.post(url, {
                    fName: fName,
                    LName: LName,
                    email: email,
                    phone: phone,
                    message: message
                });
                console.log("Success:", response);
                handleShow()

            } catch (error) {
                console.error("Error:", error);
            }

        }
    }

    return (
        <div className="section-4" id='members'>
            <div className="container">
                <div className="row text-center">
                    <div className="head">
                        <h1>Party Members</h1>
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col-lg-2 mx-lg-3 col-md-6 col-sm-6 col-12 mb-5">
                        <div className="image1">
                            <div className="content">
                                <h5>Nabeel Rashid Siddiqui</h5>
                                <p>President</p>
                                {/* <div className="social">
                                <i class="fa-brands fa-facebook"></i>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 mx-lg-3 col-md-6 col-sm-6 col-12 mb-5">
                        <div className="image2">
                            <div className="content">
                                <h5>Farrukh Rehman Khan</h5>
                                <p>Vice President</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2 mx-lg-3 col-md-6 col-sm-6 col-12 mb-5">
                        <div className="image3">
                            <div className="content">
                                <h5>Hafiz Muhammad Usman</h5>
                                <p>Secretary General</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2 mx-lg-3col-md-6 col-sm-6 col-12 mb-5">
                        <div className="image4">
                            <div className="content">
                                <h5>Maria Jadoon</h5>
                                <p>Secretary Information</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 mx-lg-3 col-md-6 col-sm-6 col-12 mb-5">
                        <div className="image5">
                            <div className="content">
                                <h5>Fawad Farooq</h5>
                                <p>Secretary Finance</p>
                            </div>
                        </div>
                    </div>
                    
                    {/* 

                    <div className="col-lg-2 mx-lg-3 col-md-6 col-sm-6 col-12 mb-5">
                        <div className="imagesheh">
                            <div className="content">
                                <h5>Shehanshah Baloch</h5>
                                <p>Social Media Manager</p>
                            </div>
                        </div>
                    </div>

                    */}

                  <div className="col-lg-2 mx-lg-3 col-md-6 col-sm-6 col-12 mb-5">
                        <div className="imagesheh">
                            <div className="content">
                                <h5>Syed Qumber Iqbal</h5>
                                <p>Legal Executive</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2 mx-lg-3 col-md-6 col-sm-6 col-12 mb-5">
                        <div className="image6">
                            <div className="content">
                                <h5>Saba Mohsin</h5>
                                <p>Secretary Social Media</p>
                            </div>
                        </div>
                    </div>

                    {/* 
                    <div className="col-lg-2 mx-lg-3 col-md-6 col-sm-6 col-12 mb-5">
                        <div className="image7">
                            <div className="content">
                                <h5>Syed Naimat Ali Naqvi</h5>
                                <p>Legal Executive</p>
                            </div>
                        </div>
                    </div>

                    */}

                    <div className="col-lg-2 mx-lg-3 col-md-6 col-sm-6 col-12 mb-5">
                        <div className="image8">
                            <div className="content">
                                <h5>Anthony Cyril Williams</h5>
                                <p>Minority Executive</p>
                            </div>
                        </div>
                    </div>

                    <Modal
                        {...props}
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={show}
                        onHide={handleClose}
                    >
                        <Modal.Header closeButton>
                            {/* <Modal.Title>Modal heading</Modal.Title> */}
                        </Modal.Header>
                        <Modal.Body>Thankyou we have recieve your message and the team of Technology Movement Pakistan will contact you soon.</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                OK
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="contact-form mt-5" id='contact'>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <div className="head">
                                <h1>Contact Us</h1>
                            </div>
                            <div className="row">
                                <h5>Personal Information</h5>
                                <div className="col-md-6">
                                    <div className="form-group mb-4">
                                        <label className='mb-2'>First Name</label>
                                        <input type="text" className='form-control' value={fName} onChange={(e) => setFname(e.target.value)} placeholder='First Name' />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-4">
                                        <label className='mb-2'>Last Name</label>
                                        <input type="text" className='form-control' value={LName} onChange={(e) => setLname(e.target.value)} placeholder='Last Name' />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group mb-4">
                                        <label className='mb-2'>Email Address</label>
                                        <input type="email" className='form-control' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email Address' />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-4">
                                        <label className='mb-2'>Phone Number</label>
                                        <input type="text" className='form-control' value={phone} onChange={(e) => setPhone(e.target.value)} placeholder='Phone Number' />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <label className='mb-2'>Your Message</label>
                                <textarea cols="30" rows="10" className='form-control' value={message} onChange={(e) => setMessage(e.target.value)} placeholder='Your Message'></textarea>
                            </div>
                            <Button type='submit' className='btn mb-4'> Submit </Button>
                            {/* <FormModal btnText= {"Submit"} message= {"Thankyou we have recieve your message and the team of Technology Movement Pakistan will contact you info"} /> */}
                            {/* <button className='btn'>Submit</button> */}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section_4
