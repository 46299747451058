import React from 'react'
import appleStore from "../../assets/img/appleStore.svg"
import androidStore from "../../assets/img/androidStore.svg"
import mobileImg from "../../assets/img/mobileImg.png"
import mobile1 from "../../assets/img/mobile1.png"
import mobile2 from "../../assets/img/mobile2.png"

export const MobileAppSection = () => {

  return (
    <>
    <section className='mobileSec'>
        <div className="container">
            <h1 className="h1">TMP Citizen Portal</h1>
            <div className="row ai-center">
                <div className="col-md-6">
                    <div className="imgDiv">
                        <img className='mobile1' src={mobile1} alt="" />
                        <img className='mobile2' src={mobile2} alt="" />
                        <img className='mobile3' src={mobile1} alt="" />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="content">
                        <h2>Download the Technology Movement Pakistan app today and help us build a tech-forward Pakistan!</h2>
                        <p>The app is available on the App Store and Google Play. Download the app today and join the Technology Movement Pakistan</p>
                        <div className="store">
                            <a target='blank' href="https://apps.apple.com/ae/app/technology-movement-pak/id1661291427" >
                                <img className='img-fluid' src={appleStore} alt="" />
                            </a>
                            <a target='blank' href="https://play.google.com/store/apps/details?id=com.citizen_portal" >
                                <img className='img-fluid' src={androidStore} alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
