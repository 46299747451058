import React from 'react'
import { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import logo from "../assets/img/footer-logo.png"
import FormModal from './Modal'

function Footer(props) {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [email, setEmail] = useState("")

    function handleSubmit(e) {
        e.preventDefault()
        if (email === "") {
            alert("Please Fill All Data")
        }
        else {
            handleShow()
        }
    }

    return (
        <>
            <footer className='footer'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <a href="/">
                                <img src={logo} alt="logo" className='img-fluid mb-4' />
                            </a>
                            <p>TECHNOLOGY Is Our Top Priority.
                            </p>
                        </div>
                        <div className="col-md-4">
                            <h5>Get In Touch</h5>
                            <p>Waqar Zaka Center, Garden Rd,
                                Saddar Saddar Town, Karachi, Karachi
                                City, Sindh
                            </p>
                            <p>info@tmp.org.pk</p>
                            <p>+92-311-2380334</p>
                        </div>
                        <div className="col-md-4">
                            <h5>Join A Newsletter</h5>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className="form-group mb-4">
                                    <label className='mb-2'>Email</label>
                                    <input type="email" className='form-control' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Enter Email Address' />
                                </div>
                                <Button type='submit' className='btn mb-4'>
                                    Subscribe
                                </Button>
                                {/* <FormModal btnText={"Subscribe"} message={"Thankyou for your Subscription"} /> */}
                                {/* <button className='btn mb-4'>Subscribe</button> */}
                            </form>
                        </div>
                        <Modal
                            {...props}
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={show}
                            onHide={handleClose}
                        >
                            <Modal.Header closeButton>
                                {/* <Modal.Title>Modal heading</Modal.Title> */}
                            </Modal.Header>
                            <Modal.Body>Thankyou for your Subscription</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    OK
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <div className="row">
                        <div className="coyright">
                            <small>Copyright ©️ 2023 Technology Movement Pakistan</small>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
