export function mobileAnimate(params) {
    var mobile1 = document.getElementsByClassName('mobile1')[0];
    var mobile3 = document.getElementsByClassName('mobile3')[0];
    // console.log(mobile1);
    
    window.addEventListener("scroll", () => {
        var yHeight= window.pageYOffset /10
        var mobile1Set= yHeight - 80
        var mobile3Set= yHeight + 20
        console.log(yHeight);
        if((mobile1Set * -1) < 50  && (mobile1Set * -1) > 25){
            mobile1.style.left = `${(mobile1Set * -1)}%`
        }
        if((mobile3Set) > 50 && (mobile3Set) < 75 ){
            mobile3.style.left = `${(mobile3Set)}%`
        }
      });
}