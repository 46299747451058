import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from './pages/home';
import Privacy from './pages/privacy';

const Main = () => {
  return (
    <Routes> {/* The Switch decides which component to show based on the current URL.*/}
      <Route exact path="/" element={<Home/>}/>
      <Route exact path="/privacy" element={<Privacy/>}/>
    </Routes>
  );
}

export default Main;