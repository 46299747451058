// import logo from '../logo.svg';
import logo from "../assets/img/logo.png"


function Header() {
  return (
    <div className="header">
      <div className="header-top bg-primary py-1">
        <div className="container">
          <ul class="header-links mb-0">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="https://www.facebook.com/techmovementpak" target="_blank">
                <i class="fa-brands fa-facebook-f"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="https://twitter.com/Techmovementpk" target="_blank">
                <i class="fa-brands fa-twitter"></i>
              </a>
            </li>


          </ul>
        </div>
      </div>
      <div className="container">
        {/*   Navbar   */}
        <nav class="navbar navbar-expand-lg navbar-light">
          <div class="container-fluid">
            <a class="navbar-brand" href="/" style={{ width: "100px" }}>
              <img src={logo} className="img-fluid" alt="logo" />
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
              <ul class="navbar-nav mb-2 mb-lg-0">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="/">Home</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#mission">Mission</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#members">Members</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#manifesto">Manifesto</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#constitution">Constitution</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#contact">Contact</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;
