import Footer from "../components/footer";
import Header from "../components/header";
import Section_1 from "../components/section_1/section_1";
import Section_2 from "../components/section_2/1-section_2";
import Section_3 from "../components/section_3/section_3";
import Section_4 from "../components/section_4/section_4";

function Privacy() {
  return (
    <div className="Privacy">
		{/* <Header /> */}
		<Header />
		<h1 style={{textAlign:"center"}}> Privacy Policy</h1>
		<hr/>
		<div style={{padding:"10px 85px 10px 85px"}}>
			<p>
				As a political party named "Technology Movement Pakistan," we place a great emphasis on privacy and its sanctity. We respect the right of Pakistani citizens to privacy and understand the importance of safeguarding their personal information. We firmly believe in transparency and have formulated a privacy policy that sheds complete light on how we collect and use personal information.
				<br /><br />
				<strong>Collection of Personal Information:</strong><br />
				As a political party, we may collect personal information, such as name, email address, phone number, and address. We will only collect such information via lawful and fair means, and with the knowledge or consent of the individual concerned. Individuals have the right to decline to provide us with personal information at any time, but that may affect their ability to participate in our events or activities.
				<br /><br />
				<strong>Use of Personal Information:</strong>
				All the personal information collected by us will only be used for the specific purposes mentioned and only in the manner it was collected. We may use personal information to response to individuals' enquiries, provide them with information regarding our policies, events and activities. We will never use personal information for any purpose other than the specific purpose for which it was collected, and will not disclose it unless specifically required by law.
				<br /><br />
				<strong>Protection of Personal Information:</strong>
				We have put in place reasonable and appropriate administrative, technical, and physical safeguards to ensure that the personal information we collect is protected against loss, misuse, or unauthorized access, disclosure, or modification. We take all necessary measures to ensure that our website and digital platforms are secure and free from any vulnerabilities or weaknesses that could be exploited by cybercriminals or hackers.
				<br /><br />
				<strong>Updates to Privacy Policy:</strong><br />
				We may update our privacy policy from time to time to adjust to changes in laws or regulations or to align with our organizational practices. Individuals will be notified of any changes made to our privacy policy, and we will seek their consent before using any new personal information for purposes other than what we had originally collected it for.
				<br /><br />
				In summary, at "Technology Movement Pakistan," we take your privacy seriously and are committed to protecting your personal information. Our privacy policy is transparent, and we are happy to discuss any concerns or questions you may have regarding how we collect and use your personal information.
			</p>
		</div>
		<Footer />
    </div>
  );
}

export default Privacy;